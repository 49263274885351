import { ServiceDetail } from "./service-detail.model"
import { Location } from "./location.model"
import { Unit } from "./unit.model"
import { Contact } from "./contact.model"

export class WorkOrder {
  id: number
  work_order_number: string  
  company_name: string
  location: Location
  unit: Unit
  service_details: ServiceDetail[]
  po_afe_number: string
  job_type: string
  job_description: string
  area: string
  contact: Contact
  description: string
  est_start_date: string
  est_end_date: string
  sales_rep: string 
  opened_by: string
  job_notes: string
  work_order_status_id: number
  is_scheduled: boolean = false

  constructor() {
  }

  static buildOne(obj: any): WorkOrder {
    
    let target: WorkOrder = Object.assign(new WorkOrder(), obj)
    if (!target.service_details || !Array.isArray(target.service_details)) target.service_details = []
    return target
  }

  static buildMany(objs: any[]): WorkOrder[] {
    const lobjs = []
    for (const i in objs) {
      if (objs.hasOwnProperty(i)) {
        lobjs.push(WorkOrder.buildOne(objs[i]))
      }
    }
    return lobjs
  }

  static findOne(objs: WorkOrder[], id: number) {
    return objs.find(f=>f.id === id)
  }
}

