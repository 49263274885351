import { WorkOrder } from "../models/work-order.model";
import { WorkOrderOpenStateModel } from "../state/work-order-open.state";

export namespace WorkOrderOpenActions {
    export class Refresh {
        static readonly type = '[API-WorkOrderOpen] Refresh'
        constructor() {}
    }
    export class Init {
        static readonly type = '[Storage-WorkOrderOpen] Init'
        constructor(public payload: WorkOrderOpenStateModel) {}
    }
    export class Set {
        static readonly type = '[API-WorkOrderOpen] Set'
        constructor(public payload: WorkOrder[]) {}
    }
}
