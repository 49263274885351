import {Injectable, SecurityContext} from '@angular/core';
import {Ng2ImgMaxService} from 'ng2-img-max';
import {DomSanitizer} from '@angular/platform-browser';
import {Photo} from '../state-man/models/photo.model';
// @ts-ignore
import {EXIF as exifShim, EXIFStatic} from 'exif-js/exif';
import { v4 as uuid } from 'uuid'

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  constructor(
      private ng2ImgMax: Ng2ImgMaxService,
      private sanitizer: DomSanitizer,
  ) {
  }

  static b64toBlob(b64Data: any, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  }

  static async blobToBase64(blob: any): Promise<any> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }
/*
  static async bufferToBase64(buffer: Uint8Array): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (reader.result) {

        }
        reader.readAsDataURL(new Blob([buffer]))
      }
      reader.readAsDataURL(new Blob([buffer]))
    });
    // remove the `data:...;base64,` part from the start
    return base64url.slice(base64url.indexOf(',') + 1);
  }
  */

 static async octetStreamToBlob(octetStream: any): Promise<Blob> {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
        const blob = new Blob([reader.result], {type: 'application/octet-stream'});
        resolve(blob);
        }
        reject('cannot convert to blob')
      };
      reader.readAsArrayBuffer(octetStream);
    });
  }

  static firstFileToBase64(fileImage: File): Promise<string> {
    return new Promise<any>((resolve, reject) => {
      const fileReader: FileReader = new FileReader();
      if (fileReader && fileImage != null) {
        fileReader.readAsDataURL(fileImage);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
        reject(new Error('No file found'));
      }
    });
  }

  static firstFileToBinary(fileImage: File): Promise<{}> {
    return new Promise<any>((resolve, reject) => {
      const fileReader: FileReader = new FileReader();
      if (fileReader && fileImage != null) {
        fileReader.readAsBinaryString(fileImage);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
        reject(new Error('No file found'));
      }
    });
  }

  static firstFileToArrayBuffer(fileImage: File): Promise<{}> {
    return new Promise<any>((resolve, reject) => {
      const fileReader: FileReader = new FileReader();
      if (fileReader && fileImage != null) {
        fileReader.readAsArrayBuffer(fileImage);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      } else {
        reject(new Error('No file found'));
      }
    });
  }

  static clearFileInput(pwaphoto: any) {
    try {
      pwaphoto.nativeElement.value = null;
    } catch (ex) {
    }
    if (pwaphoto && pwaphoto.nativeElement && pwaphoto.nativeElement.value) {
      pwaphoto.nativeElement.parentNode.replaceChild(pwaphoto.nativeElement.cloneNode(true), pwaphoto.nativeElement);
    }
  }

  static getFileFromInput(pwaphoto: any) {
    const fileList: FileList = pwaphoto.nativeElement.files;
    if (fileList && fileList.length) {
      return fileList[0];
    } else {
      return null;
    }
  }

  static getFilesFromInput(pwaphoto: any) {
    const fileList: FileList = pwaphoto.nativeElement.files;
    if (fileList && fileList.length) {
      return fileList;
    } else {
      return null;
    }
  }

  getSanitizedUrl(url: string) {
    return this.sanitizer.sanitize(SecurityContext.URL, this.sanitizer.bypassSecurityTrustResourceUrl(url));
  }

  resizeImageFile(image: File, maxWidth: any, maxHeight: any) {
    return new Promise((resolve, reject) => {
      this.ng2ImgMax.resizeImage(image, maxWidth, maxHeight).subscribe(
          result => {
            const newFile = new File([result], result.name, {type: image.type});
            resolve(newFile);
          },
          error2 => {
            reject();
          }
      );
    });
  }

  async getPhotoFromPhotoData(photo: Photo) {
    if (photo && photo.photo_data) {
      const newFile = new File([photo.photo_data], 'image', {type: photo.photo_type});
      const photo_base64 = await PhotoService.firstFileToBase64(newFile)
      const safeUrl = this.getSanitizedUrl(photo_base64); 
      photo.base64 = safeUrl ? safeUrl : ''
      photo.base64_sz = photo.base64
    }
  }

  getPhotoFromImageFile(image: File, maxWidth: any, maxHeight:any) {
    return new Promise<any>((resolve, reject) => {
      let pobj: Photo;
      PhotoService.firstFileToBase64(image)
          .then((photo_base64: any) => {
            const safeUrl = this.getSanitizedUrl(photo_base64);           
            if (safeUrl) {
              pobj = {
                id: 0, uuid: uuid(), base64: safeUrl, base64_sz: safeUrl, file_type: image.type, file_name: image.name,
                created_at: '', loaded: false, uploaded: false, photo_data: null, photo_type: '', uploading: false
              };
              return this.resizeImage(pobj, maxWidth, maxHeight);
            } else {
              reject('safeUrl is null');
              return;
            }
          })
          .then((url: any) => {
            pobj.base64 = url;
            pobj.base64_sz = url;
            resolve(pobj);
          })
          .catch((err) => {
            reject(err);
          });
    });
  }

  getOrientation(imageFile: File) {
    return new Promise<any>((resolve, reject) => {
      PhotoService.firstFileToArrayBuffer(imageFile)
          .then((result: any) => {
            const EXIF: EXIFStatic = exifShim;
            const stuff = EXIF.readFromBinaryFile(result);
            if (stuff && stuff.Orientation) {
              // 'Orientation: ' + stuff.Orientation
              resolve(stuff.Orientation);
            } else {
              // 'No EXIF so use default orientation: 0'
              resolve(0);
            }
          })
          .catch(() => {
            // 'Error, so use default orientation: 0'
            resolve(0);
          });
    });
  }

  rotateImage(image: Photo, orientation: any) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = image.base64_sz;
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const canvas = document.createElement('canvas');

        if (orientation > 4 && orientation < 9) {
          canvas.width = height;
          canvas.height = width;
        } else {
          canvas.width = width;
          canvas.height = height;
        }

        canvas.style.position = 'absolute';
        const ctx = canvas.getContext('2d');

        // transform context before drawing image
        if (ctx) {
        switch (orientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, width, 0);
            break;
          case 3:
            ctx.transform(-1, 0, 0, -1, width, height);
            break;
          case 4:
            ctx.transform(1, 0, 0, -1, 0, height);
            break;
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0);
            break;
          case 6: // Needs to rotate 180 degrees more
            ctx.transform(0, 1, -1, 0, height, 0);
            break;
          case 7:
            ctx.transform(0, -1, -1, 0, height, width);
            break;
          case 8: // Needs to rotate 180 degrees more
            ctx.transform(0, -1, 1, 0, 0, width);
            break;
          default:
            ctx.transform(1, 0, 0, 1, 0, 0);
        }

        // Draw img into canvas
        ctx.drawImage(img, 0, 0, width, height);
        const url = canvas.toDataURL();
        resolve(url);
      } else {
        reject('ctx is null');
      }
      };
      img.onerror = error => reject(error);
    });
  }

  resizeImage(image: Photo, maxWidth: any, maxHeight: any) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = image.base64_sz;
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const dim = this.getTargetDimensions(width, height, maxWidth, maxHeight);
        canvas.width = dim.targetWidth;
        canvas.height = dim.targetHeight;

        if (ctx) {
        // Draw img into canvas
        ctx.drawImage(img, 0, 0, dim.targetWidth, dim.targetHeight);
        const url = canvas.toDataURL();
        resolve(url);
        }
        else {
          reject('ctx is null');
        }
      };
      img.onerror = error => reject(error);
    });
  }

  getTargetDimensions(width: any, height: any, maxWidth: any, maxHeight: any) {
    let ratio = 0;
    let targetWidth = width;
    let targetHeight = height;
    if (width > maxWidth) {
      ratio = maxWidth / width;
      targetWidth = maxWidth;
      targetHeight = height * ratio;
      height = height * ratio;
      width = width * ratio;
    }
    if (height > maxHeight) {
      ratio = maxHeight / height;
      targetHeight = maxHeight;
      targetWidth = width * ratio;
      width = width * ratio;
      height = height * ratio;
    }
    return {targetWidth, targetHeight};
  }
}
