import { Injectable } from '@angular/core';
import { JsaActions } from 'src/app/state-man/actions/jsa.actions'
import { JsaService } from 'src/app/services/jsa.service'
import { LoggerService } from 'src/app/services/logger.service'
import { Jsa, JsaAndPhoto } from 'src/app/state-man/models/jsa.model'
import { Store } from "@ngxs/store";
import { JsaPhoto } from '../state-man/models/jsa-photo.model';
import { Mrf } from '../state-man/models/mrf.model';
import { MrfService } from './mrf.service';
import { MrfActions } from '../state-man/actions/mrf.actions';
import { Dsr } from '../state-man/models/dsr.model';
import { DsrService } from './dsr.service';
import { DsrActions } from '../state-man/actions/dsr.actions';
import { UuidAndId } from '../state-man/models/uuid-and-id';
import { DailyWorkActions } from '../state-man/actions/daily-work.actions';
import { WorkOrderActions } from '../state-man/actions/work-order.actions';
import { TechnicianActions } from '../state-man/actions/technician.actions';
import { Form } from '../state-man/models/form.model';
import { FormService } from './form.service';
import { FormActions } from '../state-man/actions/form.actions';
import { VehicleActions } from '../state-man/actions/vehicle.actions';
import { FormTypeActions } from '../state-man/actions/form-type.actions';
import { SalesOrderItemActions } from '../state-man/actions/sales-order-item.actions';
import { WorService } from './wor.service';
import { Wor } from '../state-man/models/wor.model';
import { WorActions } from '../state-man/actions/wor.actions';
import { AreaActions } from '../state-man/actions/area.actions';
import { CustomerActions } from '../state-man/actions/customer.actions';
import { LocationActions } from '../state-man/actions/location.actions';
import { UnitActions } from '../state-man/actions/unit.actions';
import { JobTypeActions } from '../state-man/actions/job-type.actions';
import { ServiceEntryService } from './service-entry.service';
import { WorkScheduleActions } from '../state-man/actions/work-schedule.actions';
import { PtoActions } from '../state-man/actions/pto.actions';
import { ContactActions } from '../state-man/actions/contact.actions';
import { UiService } from './ui.service';
import { UuidAndIdAndLineItems } from '../state-man/models/uuid-and-id-and-line-items';
import { DsrApiAddUpdateResponse } from '../state-man/models/dsr-api-add-update-response';
import { AssetActions } from '../state-man/actions/asset.actions';
import { AssetCategoryActions } from '../state-man/actions/asset-category.actions';
import { AssetFieldActions } from '../state-man/actions/asset-field.actions';
import { ExpenseService } from './expense.service';
import { ExpenseReport } from '../state-man/models/expense-report.model';
import { ExpenseReportActions } from '../state-man/actions/expense-report.actions';
import { ExpenseLine } from '../state-man/models/expense-line.model';
import { UuidAndIdAndExpenseLines } from '../state-man/models/uuid-and-id-and-expense-lines';
import { ExpenseLineActions } from '../state-man/actions/expense-line.actions';
import { ExpensePhoto } from '../state-man/models/expense-photo.model';
import { ExpenseCategoryActions } from '../state-man/actions/expense-category.actions';
import {Ticket, TicketAndPhoto} from "../state-man/models/ticket.model";
import {TicketService} from "./ticket.service";
import {TicketActions} from "../state-man/actions/ticket.actions";
import {TicketPhoto} from "../state-man/models/ticket-photo.model";
import { FormPhoto } from '../state-man/models/form-photo.model';
import { WorkOrderOpenActions } from '../state-man/actions/work-order-open.actions';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {
  is_processing_jsas = false
  is_processing_dsrs = false
  is_processing_mrfs = false
  is_processing_forms = false
  is_processing_wors = false
  is_processing_tickets = false
  is_processing_expense_reports = false
  
  constructor(
    private jsaService: JsaService,
    private mrfService: MrfService,
    private dsrService: DsrService,    
    private formService: FormService,
    private worService: WorService,
    private expenseService: ExpenseService,
    private serviceEntryService: ServiceEntryService,
    private ticketService: TicketService,
    private store: Store,
    private uiService: UiService    
    ) {}

  async processPendingJsas(jsas: Jsa[]) {    
    try {
      if (!this.is_processing_jsas) {
        this.is_processing_jsas = true
        await this.processPendingJsaDeletes(jsas)
        await this.uploadPendingJsaSaves(jsas)
        this.is_processing_jsas = false
      } 
    } catch(e) {
      LoggerService.log(e)
      this.is_processing_jsas = false
    }
  }

  async processPendingTickets(tickets: Ticket[]) {
    try {
      if (!this.is_processing_tickets) {
        this.is_processing_tickets = true;
        await this.processPendingTicketDeletes(tickets.filter( t => t.is_pending_delete ));
        await this.uploadPendingTicketSaves(tickets.filter( t => !t.is_pending_delete ));
        this.is_processing_tickets = false;
      }
    } catch (e) {
      LoggerService.log(e);
      this.is_processing_tickets = false;
    }
  };

  async processPendingMrfs(mrfs: Mrf[]) {
    try {
      if (!this.is_processing_mrfs) {
        this.is_processing_mrfs = true
        await this.uploadPendingMrfDeletes(mrfs)
        await this.uploadPendingMrfSaves(mrfs)
        this.is_processing_mrfs = false
      }
    } catch(e) {
      LoggerService.log(e)
      this.is_processing_mrfs = false
    }
  }

  async processPendingWors(wors: Wor[]) {
    try {
      if (!this.is_processing_wors) {
        this.is_processing_wors = true
        await this.uploadPendingWorDeletes(wors)
        await this.uploadPendingWorSaves(wors)
        this.is_processing_wors = false
      }
    } catch(e) {
      LoggerService.log(e)
      this.is_processing_wors = false
    }
  }

  async processPendingExpenses(expenses: ExpenseReport[]) {
    try {
      if (!this.is_processing_expense_reports) {
        this.is_processing_expense_reports = true
        await this.uploadPendingExpenseReportDeletes(expenses)
        await this.uploadPendingExpenseReportSaves(expenses)
        this.is_processing_expense_reports = false
      }
    } catch(e) {
      LoggerService.log(e)
      this.is_processing_expense_reports = false
    }
  }

  async processPendingDsrs(dsrs: Dsr[]) {
    try {      
      if (!this.is_processing_dsrs) {        
        this.is_processing_dsrs = true
        await this.uploadPendingDsrDeletes(dsrs)
        await this.uploadPendingDsrSaves(dsrs)
        await this.updatePendingServiceEntryApprovals(dsrs)
        this.is_processing_dsrs = false
      } 
    } catch(e) {
      LoggerService.log(e)
      this.is_processing_dsrs = false
    }
  }

  async processPendingForms(forms: Form[]) {
    try {
      if (!this.is_processing_forms) {
        this.is_processing_forms = true
        await this.uploadPendingFormDeletes(forms)
        await this.uploadPendingFormSaves(forms)
        this.is_processing_forms = false
      }
    } catch(e) {
      LoggerService.log(e)
      this.is_processing_forms = false
    }
  }

  async uploadPendingJsaSaves(jsas: Jsa[]) {
    try {      
        for (const jsa of jsas) {    
          for (const photo of jsa.photos) {              
            if (!photo.uploaded) {              
              let apiPhoto            
              try {             
                const jsaAndPhotoCurrent: JsaAndPhoto = {jsa_id: jsa.id, photo: photo}
                this.store.dispatch(new JsaActions.ApiStartUploading(jsaAndPhotoCurrent))  
                apiPhoto = await this.jsaService.addPhoto(jsa, photo)  
                this.store.dispatch(new JsaActions.ApiStopUploading(jsaAndPhotoCurrent))          
              }
              catch (error) {                
                const jsaAndPhotoCurrent: JsaAndPhoto = {jsa_id: jsa.id, photo: photo}
                this.store.dispatch(new JsaActions.ApiStopUploading(jsaAndPhotoCurrent))  
                throw error
              }                  
              let addedPhoto:JsaPhoto = await JsaPhoto.buildOne(apiPhoto)       
              addedPhoto.uuid = photo.uuid              
              addedPhoto.file_name = photo.file_name
              addedPhoto.file_type = photo.file_type
              addedPhoto.uploaded = true           
              const jsaAndPhoto: JsaAndPhoto = {jsa_id: jsa.id, photo: addedPhoto}
              this.store.dispatch(new JsaActions.ApiAddedPhotoToJsa(jsaAndPhoto))                             
            }
          }
        }                    
    } catch (error) {            
      LoggerService.log(error)      
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async processPendingJsaDeletes(jsas: Jsa[]) {
    try {          
        for (const jsa of jsas) {            
          for (const photo of jsa.deleted_photos) {    
            if (photo.id) {                                     
              await this.jsaService.deletePhoto(photo)
            }                       
            const jsaAndPhoto: JsaAndPhoto = {jsa_id: jsa.id, photo: photo}
            this.store.dispatch(new JsaActions.ApiDeletedPhotoFromJsa(jsaAndPhoto))                         
          }
        }      
    } catch (error) {      
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingTicketSaves(tickets: Ticket[]) {
    const processPhotos = async (ticket: Ticket) => {
      for (const photo of ticket.photos) {
        if (ticket.id && !photo.uploaded) {
          let apiPhoto
          try {
            const ticketAndPhotoCurrent: TicketAndPhoto = { ticket_id: ticket.id, photo: photo };
            this.store.dispatch( new TicketActions.ApiStartUploading(ticketAndPhotoCurrent));
            apiPhoto = await this.ticketService.addPhoto( ticket, photo );
            this.store.dispatch(new TicketActions.ApiStopUploading(ticketAndPhotoCurrent));
          } catch (error) {
            const ticketAndPhotoCurrent: TicketAndPhoto = { ticket_id: ticket.id, photo: photo };
            this.store.dispatch(new TicketActions.ApiStopUploading(ticketAndPhotoCurrent));
            throw error
          }
          let addedPhoto: TicketPhoto = await TicketPhoto.buildOne(apiPhoto)
          addedPhoto.uuid = photo.uuid
          addedPhoto.file_name = photo.file_name
          addedPhoto.file_type = photo.file_type
          addedPhoto.uploaded = true
          const ticketAndPhoto: TicketAndPhoto = {ticket_id: ticket.id, photo: addedPhoto}
          this.store.dispatch(new TicketActions.ApiAddedPhotoToTicket(ticketAndPhoto))
        }
      }
    }
    try {
      for (const ticket of tickets) {
        if (!ticket.id) {
          try {
            this.ticketService.addTicket(ticket).then( t => {
              const _t = Ticket.buildOne(t);
              _t.photos = ticket.photos;
              processPhotos(_t);
            });
          } catch (error) {
            throw error
          }
        } else await processPhotos(ticket);
      }
    } catch (error) {
      LoggerService.log(error)
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')
    }
  }

  async processPendingTicketDeletes(tickets: Ticket[]) {
    try {
      for (const ticket of tickets) {
        if( ticket.is_pending_delete) {
          await this.ticketService.deleteTicket(ticket);
          this.store.dispatch(new TicketActions.DeleteOne(ticket))
        }
      }
    } catch (error) {
      LoggerService.log(error)
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')
    }
  };

  async uploadPendingMrfSaves(mrfs: Mrf[]) {
    try {              
        for (const mrf of mrfs) {  
          if (mrf.is_pending_save) {
            if (mrf.id <= 0) {
              const apiObj = await this.mrfService.addMrf(mrf)                
              if (apiObj) {
                const id: number = apiObj.id
                const uuid: string = mrf.uuid
                const mrf_line_items = apiObj.mrf_line_items
                const payload: UuidAndIdAndLineItems = {uuid, id, mrf_line_items}                             
                this.store.dispatch(new MrfActions.ApiAddedOne(payload))  
              } 
            } else {
              const apiObj = await this.mrfService.updateMrf(mrf)                
              if (apiObj) {
                const id: number = apiObj.id
                const uuid: string = mrf.uuid
                const mrf_line_items = apiObj.mrf_line_items
                const payload: UuidAndIdAndLineItems = {uuid, id, mrf_line_items}                                   
                this.store.dispatch(new MrfActions.ApiUpdatedOne(payload))  
              } 
            }
          }
        }  
    } catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingMrfDeletes(mrfs: Mrf[]) {    
    try {              
        for (const mrf of mrfs) {  
          if (mrf.is_pending_delete) {                    
            const status = await this.mrfService.deleteMrf(mrf)                 
            if (status && status === 'OK') {
              const id: number = mrf.id
              const uuid: string = mrf.uuid
              const payload: UuidAndId = {uuid, id}                   
              this.store.dispatch(new MrfActions.ApiDeletedOne(payload))  
            }             
          }
        }     
    } catch (error) {         
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingWorSaves(wors: Wor[]) {    
    try {              
        for (const wor of wors) {  
          if (wor.is_pending_save) {
            if (wor.id <= 0) {
              const apiObj = await this.worService.addWor(wor)  
              if (apiObj) {
                const id: number = apiObj.id
                const uuid: string = wor.uuid
                const payload: UuidAndId = {uuid, id}                   
                this.store.dispatch(new WorActions.ApiAddedOne(payload))  
              } 
            } else {
              const apiObj = await this.worService.updateWor(wor)  
              if (apiObj) {
                const id: number = apiObj.id
                const uuid: string = wor.uuid
                const payload: UuidAndId = {uuid, id}                   
                this.store.dispatch(new WorActions.ApiUpdatedOne(payload))  
              } 
            }
          }
        }                    
    } catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingWorDeletes(wors: Wor[]) {    
    try {              
        for (const wor of wors) {  
          if (wor.is_pending_delete) {            
            const status = await this.worService.deleteWor(wor)              
            if (status && status === 'OK') {
              const id: number = wor.id
              const uuid: string = wor.uuid
              const payload: UuidAndId = {uuid, id}                   
              this.store.dispatch(new WorActions.ApiDeletedOne(payload))  
            }             
          }
        }                    
    } catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingExpenseReportSaves(expenseReports: ExpenseReport[]) {
    try {
      for (const expenseReport of expenseReports) {
        if (expenseReport.is_pending_save) {
          if (expenseReport.id <= 0) {
            const apiObj = await this.expenseService.addExpenseReport(expenseReport)
            if (apiObj) {
              apiObj.uuid = expenseReport.uuid
              apiObj.expense_lines = ExpenseLine.buildMany(apiObj.expense_lines)

              await this.getAndSetExpenseLinePhotos(apiObj.expense_lines)

              this.store.dispatch(new ExpenseReportActions.ApiAddedOne(apiObj))
              this.store.dispatch(new ExpenseReportActions.UpdateOne(apiObj))
            }
          } else {
            const apiObj = await this.expenseService.updateExpenseReport(expenseReport)
            if (apiObj) {
              const id: number = apiObj.id
              const uuid: string = expenseReport.uuid
              apiObj.uuid = uuid
              const expense_lines = ExpenseLine.buildMany(apiObj.expense_lines)
              apiObj.expense_lines = expense_lines

              for(const prevExpenseLine of expenseReport.expense_lines) {
                if(prevExpenseLine.photo && !prevExpenseLine.photo.uploading) {
                  const newExpenseLine = apiObj.expense_lines.find(n => n.id === prevExpenseLine.id)
                  if(newExpenseLine) newExpenseLine.photo = await ExpensePhoto.buildOne(prevExpenseLine.photo)
                }
              }

              await this.getAndSetExpenseLinePhotos(apiObj.expense_lines)

              const payload: UuidAndIdAndExpenseLines = {uuid, id, expense_lines}
              this.store.dispatch(new ExpenseReportActions.ApiUpdatedOne(payload))
              this.store.dispatch(new ExpenseReportActions.UpdateOne(apiObj))
            }
          }
        }
      }
    } catch (error) {
      LoggerService.log(error)
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')
    }
  }

  async getAndSetExpenseLinePhotos(expenseLines: ExpenseLine[]) {
    const promises = []
    for(const expenseLine of expenseLines) {
      if(expenseLine.has_receipt && !expenseLine.photo) {
        promises.push(await this.getAndSetExpenseLinePhoto(expenseLine))
      }
    }
    await Promise.all(promises)
  }

  async getAndSetExpenseLinePhoto(expenseLine: ExpenseLine) {
      const photo = await this.expenseService.getPhotoByExpenseLineId(expenseLine.id)
      if (photo) expenseLine.photo = await ExpensePhoto.buildOne({...photo, has_server_photo: true})
  }

  async uploadPendingExpenseReportDeletes(expenseReports: ExpenseReport[]) {
    try {
      for (const expenseReport of expenseReports) {
        if (expenseReport.is_pending_delete) {
          const status = await this.expenseService.deleteExpenseReport(expenseReport)
          if (status && status === 'OK') {
            const id: number = expenseReport.id
            const uuid: string = expenseReport.uuid
            const expense_lines = ExpenseLine.buildMany([])
            const payload: UuidAndIdAndExpenseLines = { uuid, id, expense_lines }
            this.store.dispatch(new ExpenseReportActions.ApiDeletedOne(payload))
          }
        } else {
          this.uploadPendingExpenseLinesDeletes(expenseReport.expense_lines)
        }
      }
    } catch (error) {
      LoggerService.log(error)
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')
    }
  }

  async uploadPendingExpenseLinesDeletes(expense_lines: ExpenseLine[]) {
    try {
      for (const expenseLine of expense_lines) {
        if (expenseLine.is_pending_delete) {
          const status = await this.expenseService.deleteExpenseLine(expenseLine)
          if (status && status === 'OK') {
            const id: number = expenseLine.id
            const uuid: string = expenseLine.uuid
            const payload: UuidAndId = { uuid, id }
            this.store.dispatch(new ExpenseLineActions.ApiDeletedOne(payload))
          }
        }
      }
    } catch (error) {
      LoggerService.log(error)
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')
    }
  }

  async uploadPendingDsrSaves(dsrs: Dsr[]) : Promise<void> {
    try {      
        for (const dsr of dsrs) {  
          if (dsr.is_pending_save) {
            if (dsr.id <= 0) {
              const apiObj = await this.dsrService.addDsr(dsr)  
              if (apiObj) {
                const id: number = apiObj.id
                const uuid: string = dsr.uuid
                const service_entries = apiObj.service_entries
                const dsr_status_id = apiObj.dsr_status_id
                const rejected_reason = apiObj.rejected_reason
                const payload: DsrApiAddUpdateResponse = {uuid, id, service_entries, dsr_status_id, rejected_reason}   
                this.store.dispatch(new DsrActions.ApiAddedOne(payload))  
              } 
            } else {
              const apiObj = await this.dsrService.updateDsr(dsr)  
              if (apiObj) {
                const id: number = apiObj.id
                const uuid: string = dsr.uuid
                const service_entries = apiObj.service_entries
                const dsr_status_id = apiObj.dsr_status_id
                const rejected_reason = apiObj.rejected_reason
                const payload: DsrApiAddUpdateResponse = {uuid, id, service_entries, dsr_status_id, rejected_reason}                   
                this.store.dispatch(new DsrActions.ApiUpdatedOne(payload))  
              } 
            }
          }
        }  
    } catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingDsrDeletes(dsrs: Dsr[]) {    
    try {              
        for (const dsr of dsrs) {  
          if (dsr.is_pending_delete) {            
            const status = await this.dsrService.deleteDsr(dsr)              
            if (status && status === 'OK') {
              const id: number = dsr.id
              const uuid: string = dsr.uuid
              const payload: UuidAndId = {uuid, id}                   
              this.store.dispatch(new DsrActions.ApiDeletedOne(payload))  
            }             
          }
        }                    
    } catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async updatePendingServiceEntryApprovals(dsrs: Dsr[]) {    
    try {      
        for (const dsr of dsrs) {            
          const ids = []
          let employee_approval_date = null
          for (const se of dsr.service_entries) {
            if (se.is_approval_pending) {                     
              ids.push(se.id)  
              employee_approval_date = se.employee_approval_date            
            } 
          }   
          
          if (ids.length && employee_approval_date) {                       
            await this.serviceEntryService.approve(ids, employee_approval_date)              
            this.store.dispatch(new DsrActions.ApiUpdatedServiceEntriesApproval(dsr))   
          }
          
        }                    
    } catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async uploadPendingFormSaves(iforms: Form[]) {    
    try {   
      
      
      const forms: Form[] = []
      for (const of of iforms) {
          if (of.id <= 0) {
              // Only keep pending forms (id === 0) if the guid does not already 
              // exist with a non-zero id.
              const found = iforms.find(f => f.id !== 0 && f.uuid === of.uuid)
              if (!found) {
                  // Only keep form if don't already have one with the same id/uuid
                  const found2 = forms.find(f => f.id === of.id && f.uuid === of.uuid)
                  if (!found2) forms.push(of) 
              }
          } else {
              // Only keep form if don't already have one with the same id/uuid
              const found2 = forms.find(f => f.id === of.id && f.uuid === of.uuid)
              if (!found2) forms.push(of) 
          }
      }

      for (const form of forms) {          
        if (form.is_pending_save) {
          if (form.id <= 0) {
            const apiObj = await this.formService.addForm(form)  
            if (apiObj) {
              const id: number = apiObj.id
              const uuid: string = form.uuid
              const payload: UuidAndId = {uuid, id}                 
              if(apiObj?.form_photos_to_download) apiObj.form_photos = await this.getAndSetFormPhotos(form, apiObj.form_photos_to_download)
              this.store.dispatch(new FormActions.ApiAddedOne(payload))
              this.store.dispatch(new FormActions.UpdateOne(apiObj))
            } 
          } else {
            const apiObj = await this.formService.updateForm(form)  
            if (apiObj) {
              const id: number = apiObj.id
              const uuid: string = form.uuid
              const payload: UuidAndId = {uuid, id}
              if(apiObj?.form_photos_to_download) apiObj.form_photos = await this.getAndSetFormPhotos(form, apiObj.form_photos_to_download)
              this.store.dispatch(new FormActions.ApiUpdatedOne(payload))
              this.store.dispatch(new FormActions.UpdateOne(apiObj))
            }
          }
        }
      }                    
    } catch (error) {          
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async getAndSetFormPhotos(form: Form, form_photos_to_download: number[]): Promise<FormPhoto[]> {
    let form_photos: FormPhoto[] = []
    for(const photo_id of form_photos_to_download) {
      const existingPhoto = form.form_photos ? form.form_photos.find(p => p.id === photo_id) : null
      if(!existingPhoto) {
        const photo = await this.formService.getFormPhoto(photo_id)
        if(photo) form_photos.push(photo)
      } else {
        form_photos.push(existingPhoto)
      }
    }
    return form_photos
  }

  async uploadPendingFormDeletes(forms: Form[]) {    
    try {              
        for (const form of forms) {  
          if (form.is_pending_delete) {            
            const status = await this.formService.deleteForm(form)              
            if (status && status === 'OK') {
              const id: number = form.id
              const uuid: string = form.uuid
              const payload: UuidAndId = {uuid, id}                   
              this.store.dispatch(new FormActions.ApiDeletedOne(payload))  
            }             
          }
        }                    
    } catch (error) {            
      LoggerService.log(error)   
      await this.uiService.showToast('An unexpected error occurred and has been sent to the support team.')    
    } 
  }

  async refresh() {
    try {      
      //this.uiService.startWait("Refreshing...");
      this.store.dispatch(new PtoActions.Refresh())
      this.store.dispatch(new JsaActions.Refresh())
      this.store.dispatch(new MrfActions.Refresh()) 
      this.store.dispatch(new DsrActions.Refresh())
      this.store.dispatch(new DailyWorkActions.Refresh())
      this.store.dispatch(new WorkOrderActions.Refresh())
      this.store.dispatch(new TechnicianActions.Refresh())
      this.store.dispatch(new FormActions.Refresh())
      this.store.dispatch(new VehicleActions.Refresh())
      this.store.dispatch(new FormTypeActions.Refresh())
      this.store.dispatch(new SalesOrderItemActions.Refresh())
      this.store.dispatch(new WorActions.Refresh())
      this.store.dispatch(new ExpenseReportActions.Refresh())
      this.store.dispatch(new ExpenseCategoryActions.Refresh())
      this.store.dispatch(new AreaActions.Refresh())
      this.store.dispatch(new CustomerActions.Refresh())
      this.store.dispatch(new ContactActions.Refresh())
      this.store.dispatch(new LocationActions.Refresh())
      this.store.dispatch(new UnitActions.Refresh())
      this.store.dispatch(new JobTypeActions.Refresh())
      this.store.dispatch(new WorkScheduleActions.Refresh())
      this.store.dispatch(new AssetActions.Refresh())
      this.store.dispatch(new AssetCategoryActions.Refresh())
      this.store.dispatch(new AssetFieldActions.Refresh())
      this.store.dispatch(new TicketActions.Refresh())
      this.store.dispatch(new WorkOrderOpenActions.Refresh())      

      // Do this to trigger uploading pending data
      await this.trigger_pending()
      //this.uiService.stopWait()
    } catch (e) {
      //this.uiService.stopWait()
      LoggerService.log(e)
    }  
  }  

  async trigger_pending() {
    try {
      // Do this to trigger uploading pending data
      this.store.dispatch(new JsaActions.IncrementPendingCount())
      this.store.dispatch(new MrfActions.IncrementPendingCount())
      this.store.dispatch(new DsrActions.IncrementPendingCount())
      this.store.dispatch(new FormActions.IncrementPendingCount())
      this.store.dispatch(new WorActions.IncrementPendingCount())
      this.store.dispatch(new ExpenseReportActions.IncrementPendingCount())
      this.store.dispatch(new TicketActions.IncrementPendingCount())
    } catch (e) {
      LoggerService.log(e)
    }  
  }  

}
